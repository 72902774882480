/*!
*    ______ _       _   _
*    |  ____| |     | | (_)
*    | |__  | | __ _| |_ _  ___ ___  _ __
*    |  __| | |/ _` | __| |/ __/ _ \| '_ \
*    | |    | | (_| | |_| | (_| (_) | | | |
*    |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*         https://www.flaticon.com
*/

@font-face {
    font-family: "flaticon_rexion";
    src: url("./flaticon_rexion.woff2?72d6395a8420c078c68a04d09c955ebc") format("woff2"),
url("./flaticon_rexion.woff?72d6395a8420c078c68a04d09c955ebc") format("woff"),
url("./flaticon_rexion.eot?72d6395a8420c078c68a04d09c955ebc#iefix") format("embedded-opentype"),
url("./flaticon_rexion.ttf?72d6395a8420c078c68a04d09c955ebc") format("truetype"),
url("./flaticon_rexion.svg?72d6395a8420c078c68a04d09c955ebc#flaticon_rexion") format("svg")
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_rexion !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-facebook-app-symbol:before {
    content: "\f101";
}
.flaticon-twitter:before {
    content: "\f102";
}
.flaticon-twitter-1:before {
    content: "\f103";
}
.flaticon-linkedin:before {
    content: "\f104";
}
.flaticon-pinterest:before {
    content: "\f105";
}
.flaticon-instagram:before {
    content: "\f106";
}
.flaticon-location:before {
    content: "\f107";
}
.flaticon-bed:before {
    content: "\f108";
}
.flaticon-bathtub:before {
    content: "\f109";
}
.flaticon-network:before {
    content: "\f10a";
}
.flaticon-play:before {
    content: "\f10b";
}
.flaticon-star:before {
    content: "\f10c";
}
.flaticon-right-arrow:before {
    content: "\f10d";
}
.flaticon-left-arrow:before {
    content: "\f10e";
}
.flaticon-arrow-right:before {
    content: "\f10f";
}
.flaticon-arrow-right-1:before {
    content: "\f110";
}
.flaticon-arrow-left:before {
    content: "\f111";
}
.flaticon-arrow-left-1:before {
    content: "\f112";
}
.flaticon-up-arrow:before {
    content: "\f113";
}
.flaticon-right-up:before {
    content: "\f114";
}
.flaticon-apartment:before {
    content: "\f115";
}
.flaticon-workplace:before {
    content: "\f116";
}
.flaticon-villa:before {
    content: "\f117";
}
.flaticon-maps-and-flags:before {
    content: "\f118";
}
.flaticon-call:before {
    content: "\f119";
}
.flaticon-email:before {
    content: "\f11a";
}
.flaticon-email-1:before {
    content: "\f11b";
}
.flaticon-call-1:before {
    content: "\f11c";
}
.flaticon-right-up-1:before {
    content: "\f11d";
}
.flaticon-menu:before {
    content: "\f11e";
}
.flaticon-home:before {
    content: "\f11f";
}
.flaticon-check-mark:before {
    content: "\f120";
}
.flaticon-land:before {
    content: "\f121";
}
.flaticon-blueprint:before {
    content: "\f122";
}
.flaticon-payment:before {
    content: "\f123";
}
.flaticon-offer:before {
    content: "\f124";
}
.flaticon-construction:before {
    content: "\f125";
}
.flaticon-service:before {
    content: "\f126";
}
.flaticon-company:before {
    content: "\f127";
}
.flaticon-house:before {
    content: "\f128";
}
.flaticon-plus:before {
    content: "\f129";
}
.flaticon-right-arrow-1:before {
    content: "\f12a";
}
.flaticon-search:before {
    content: "\f12b";
}
.flaticon-down-chevron:before {
    content: "\f12c";
}
.flaticon-influencer:before {
    content: "\f12d";
}
.flaticon-flat:before {
    content: "\f12e";
}
.flaticon-coworkers:before {
    content: "\f12f";
}
.flaticon-modern-house:before {
    content: "\f130";
}
.flaticon-bed-1:before {
    content: "\f131";
}
.flaticon-bath-tub:before {
    content: "\f132";
}
.flaticon-back:before {
    content: "\f133";
}
.flaticon-double-arrow:before {
    content: "\f134";
}
.flaticon-fax:before {
    content: "\f135";
}
.flaticon-ribbon:before {
    content: "\f136";
}
.flaticon-user:before {
    content: "\f137";
}
.flaticon-location-1:before {
    content: "\f138";
}
.flaticon-telephone-call:before {
    content: "\f139";
}
.flaticon-residential:before {
    content: "\f13a";
}
.flaticon-home-1:before {
    content: "\f13b";
}
.flaticon-pdf:before {
    content: "\f13c";
}
.flaticon-calendar:before {
    content: "\f13d";
}
.flaticon-protection:before {
    content: "\f13e";
}
.flaticon-user-1:before {
    content: "\f13f";
}
.flaticon-user-2:before {
    content: "\f140";
}
.flaticon-dot:before {
    content: "\f141";
}
.flaticon-comment:before {
    content: "\f142";
}
.flaticon-protected:before {
    content: "\f143";
}
.flaticon-play-button-arrowhead:before {
    content: "\f144";
}
.flaticon-dollar-sign:before {
    content: "\f145";
}
.flaticon-dollar-currency-symbol:before {
    content: "\f146";
}

